<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="
        () => {
          clearForm();
          tk_application_id = null;
        }
      "
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <div style="display: none">{{ refresh }}</div>
          <h5 class="mb-0">
            {{ taskLocal.tk_id && allowEdit ? "Update Task" : "" }}
            {{ !taskLocal.tk_id && allowEdit ? "Add Task" : "" }}
            {{ !allowEdit ? "Task Details" : "" }}
          </h5>
          <div>
            <feather-icon
              v-show="taskLocal.tk_id"
              v-if="allowEdit"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                () => {
                  addTask({ ...taskLocal, is_active: 'N' });
                }
              "
            />
            <svg
              v-if="taskLocal.is_important === 'Y' ? false : true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="#00d09b"
              stroke="2"
              class="bi bi-star ml-1"
              viewBox="0 0 16 16"
              @click="
                if (allowEdit) {
                  taskLocal.is_important =
                    taskLocal.is_important === 'Y' ? 'N' : 'Y';
                  addTask(taskLocal);
                }
              "
            >
              <path
                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="#00d09b"
              class="bi bi-star-fill ml-1"
              viewBox="0 0 16 16"
              @click="
                if (allowEdit) {
                  taskLocal.is_important =
                    taskLocal.is_important === 'Y' ? 'N' : 'Y';
                  addTask(taskLocal);
                }
              "
              v-else
            >
              <path
                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
              />
            </svg>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(addTask(taskLocal))"
            @reset.prevent="resetForm"
          >
            <!-- Title -->
            <validation-provider
              v-if="allowEdit"
              #default="validationContext"
              name="Subject"
              rules="required"
            >
              <b-form-group label="Subject" label-for="task-title">
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.tk_title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Subject"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div v-else class="my-2">
              <p>Title</p>
              <h4>{{ taskLocal.tk_title }}</h4>
            </div>

            <!-- Assigned Stduents -->
            <b-form-group label="Student" label-for="student" v-if="allowEdit">
              <v-select
                :options="$store.getters['user/getAssignedStudents']"
                :reduce="(student) => student.user_id"
                label="user_name"
                :close-on-select="true"
                input-id="student"
                :clearable="true"
                v-model="taskLocal.tk_student_id"
                @input="
                  (e) => {
                    $nextTick(() => {
                      getAllStudentApplications();
                      getAllAssignedStaff();
                    });
                  }
                "
              >
                <template #option="{ user_profile_image, user_name }">
                  <b-avatar
                    size="sm"
                    v-if="user_profile_image"
                    :src="FILESURL + user_profile_image"
                  />
                  <span class="ml-50"> {{ user_name }}</span>
                </template>

                <template #selected-option="{ user_profile_image, user_name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="FILESURL + user_profile_image"
                    v-if="user_profile_image"
                  />
                  <span class="ml-50"> {{ user_name }}</span>
                </template>
              </v-select>
            </b-form-group>
            <div v-else class="my-2">
              <p>Assigned Student</p>
              <h4>{{ taskLocal.student_user_name }}</h4>
            </div>
            <!-- Assignee -->
            <b-form-group
              label="Assignee"
              label-for="assignee"
              v-if="allowEdit"
            >
              <v-select
                v-model="taskLocal.tk_assignee_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="assigneeOptions"
                :reduce="(assignee) => assignee.user_id"
                label="user_firstname"
                class="assignee-selector"
                input-id="assignee"
                rules="required"
                :close-on-select="true"
                :disabled="taskLocal.tk_student_id ? false : true"
                @input="onAssigneeInput"
              >
                <template
                  #option="{
                    user_profile_image,
                    user_firstname,
                    user_lastname,
                  }"
                >
                  <b-avatar
                    size="sm"
                    v-if="user_profile_image"
                    :src="FILESURL + user_profile_image"
                  />
                  <span class="ml-50">
                    {{ `${user_firstname} ${user_lastname}` }}</span
                  >
                </template>

                <template
                  #selected-option="{
                    user_profile_image,
                    user_firstname,
                    user_lastname,
                  }"
                >
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="FILESURL + user_profile_image"
                    v-if="user_profile_image"
                  />
                  <span class="ml-50">
                    {{ `${user_firstname} ${user_lastname}` }}</span
                  >
                </template>
              </v-select>
            </b-form-group>

            <div v-else class="my-2">
              <p>Assignee</p>
              <h4>{{ taskLocal.assignee_user_name }}</h4>
            </div>

            <!-- Assigned Student Applications -->
            <b-form-group label="Student Application" v-if="allowEdit">
              <v-select
                v-model="tk_application_id"
                :options="studentApplicationOptions.applications"
                :reduce="(application) => application.sa_mapper_id"
                label="course_name"
                class="assignee-selector"
                id="student_application"
                :close-on-select="true"
                :disabled="taskLocal.tk_student_id ? false : true"
                @input="
                  (e) => {
                    taskLocal.tk_application_id = e;
                  }
                "
              >
                <template #option="data">
                  <h6 class="ml-50 h5">{{ data.university_name }}</h6>
                  <p class="ml-50">{{ data.course_name }}</p>
                </template>

                <template #selected-option="data">
                  <div class="d-flex flex-column">
                    <h6 class="h5">{{ data.university_name }}</h6>
                    <p class="">{{ data.course_name }}</p>
                  </div>
                </template>
              </v-select>
            </b-form-group>
            <div v-else class="my-2">
              <p>Student Application</p>
              <h4>{{ taskLocal.university_name }}</h4>
              <h5>{{ taskLocal.course_name }}</h5>
            </div>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              v-if="allowEdit"
            >
              <b-form-group label="Start Date" label-for="start-date">
                <flat-pickr
                  v-model="taskLocal.start_date"
                  class="form-control"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div v-else class="my-2">
              <p>Start Date</p>
              <h4>
                {{ moment(taskLocal.start_date).format("DD MMM YYYY") }}
              </h4>
            </div>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              name="Deadline Date"
              v-if="allowEdit"
            >
              <b-form-group label="Deadline Date" label-for="due-date">
                <flat-pickr
                  v-model="taskLocal.deadline_date"
                  class="form-control"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div v-else class="my-2">
              <p>Deadline Date</p>
              <h4>
                {{ moment(taskLocal.deadline_date).format("DD MMM YYYY") }}
              </h4>
            </div>

            <!--Priority -->
            <b-form-group label="Priority" label-for="tag" v-if="allowEdit">
              <v-select
                v-model="taskLocal.tk_priority"
                :options="priorityOptions"
                label="label"
                :reduce="(priority) => priority.value"
                :close-on-select="true"
                input-id="tags"
              />
            </b-form-group>
            <div v-else class="my-2">
              <p>Priority Date</p>
              <h4>
                {{
                  taskLocal.tk_priority[0].toUpperCase() +
                  taskLocal.tk_priority.slice(1)
                }}
              </h4>
            </div>

            <!-- Description -->
            <b-form-group
              label="Description"
              label-for="task-description"
              v-if="allowEdit"
            >
              <quill-editor
                id="quil-content"
                v-model="taskLocal.tk_description"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>
            <div v-else class="my-2">
              <h4>Description</h4>
              <div v-html="taskLocal.tk_description"></div>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2" v-if="allowEdit">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskLocal.tk_id ? "Update" : "Add " }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BAvatar,
  BButton,
  BFormTextarea,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import { avatarText } from "@core/utils/filter";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import useTaskHandler from "./useTaskHandler";
import moment from "moment";
import CommonServices from "@/apiServices/CommonServices";
import { ref, onUnmounted, computed, watch } from "@vue/composition-api";
import { FILESURL } from "@/config";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isTaskHandlerSidebarActive",
    event: "update:is-task-handler-sidebar-active",
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    fetchTasks: {
      type: Function,
      required: true,
    },
    addTask: {
      type: Function,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    };
  },

  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetTaskLocal, props.clearTaskData);

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Write your description",
    };

    const assigneeOptions = ref([]);
    const studentApplicationOptions = ref([]);
    const refresh = ref(false);

    // function fetchAllAssignees() {
    //   CommonServices.getTaskUsers()
    //     .then((response) => {
    //       if (response.data.status) {
    //         assigneeOptions.value = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("Error in getting fetchAllAssignees", err);
    //     });
    // }

    function onAssigneeInput(i) {
      taskLocal.value.tk_assignee_id = i;
      refresh.value = !refresh.value;
    }

    function getAllAssignedStaff(initial = false) {
      if (!initial) {
        taskLocal.value.tk_assignee_id = null;
      }
      if (!taskLocal.value.tk_student_id) {
        assigneeOptions.value = [];
        return;
      }

      CommonServices.getAssignedStaffToStudent({
        student_user_id: taskLocal.value.tk_student_id,
      })
        .then((response) => {
          if (response.data.status) {
            assigneeOptions.value = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in getting getAllAssignedStaff ", err);
        });
    }

    const tk_application_id = ref();

    function getAllStudentApplications(initial = false) {
      if (!taskLocal.value.tk_student_id) {
        return;
      }

      if (!initial) {
        taskLocal.value.tk_application_id = null;
        tk_application_id.value = null;
      }

      tk_application_id.value = taskLocal.value.tk_application_id;

      CommonServices.getStudentApplications({
        student_user_id: taskLocal.value.tk_student_id,
      })
        .then((response) => {
          if (response.data.status) {
            studentApplicationOptions.value = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in getting getAllStudentApplications ", err);
        });
    }

    const priorityOptions = [
      { label: "Low", value: "low" },
      { label: "Medium", value: "medium" },
      { label: "High", value: "high" },
    ];

    watch((isTaskHandlerSidebarActive) => {
      if (isTaskHandlerSidebarActive) {
        if (taskLocal.value.tk_student_id) {
          getAllAssignedStaff(true);
          getAllStudentApplications(true);
        }
      }
    });

    return {
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      tagOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
      FILESURL,
      priorityOptions,
      // studentOptions,
      studentApplicationOptions,
      getAllAssignedStaff,
      onAssigneeInput,
      getAllStudentApplications,
      moment,
      tk_application_id,
      refresh,
      // addTask,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
